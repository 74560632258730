.App {
    min-height: 100vh;
    box-sizing: border-box;
    position: relative;
    padding: 61px 0;
    

    &__container {
        width: 100%;
        min-height: calc(100vh - 280px);
        color: #FFFFFF;
        margin-bottom: 76px;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    @media screen and (max-width: 600px) {
        padding: 30px 0;
    }
}
