.header-address {
  background: rgba(255, 255, 255, 0.4);
  border-radius: 88px;
  padding: 8px 10px;

  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  color: #000000;

  cursor: pointer;
}