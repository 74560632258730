.withdraw-card {
  width: 589px;
  height: 195px;
  margin-bottom: 8px;
  background: #F9D549;
  cursor: pointer;

  &__date {
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    opacity: 0.4;
  }

  &__value,
  &__points,
  &__pointPerDay {
    font-weight: 700;
    font-size: 32px;
    line-height: 120%;
  }

  &__points,
  &__pointPerDay {
    opacity: 0.4;
  }

  &__line {
    display: flex;
    justify-content: space-between;

    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    margin-top: 32px;

    &--withdraw {
      justify-content: flex-end !important;
      font-weight: bold !important;
    }
  }

  @media screen and (max-width: 600px) {
    width: 100% !important;
    height: 100% !important;

    &__date {
      font-size: 14px !important;
    }

    &__value,
    &__points,
    &__pointPerDay {
      font-size: 22px !important;
    }

    &__line {
      font-size: 12px !important;
    }
  }
}
