.home-page-mobile {
  margin-bottom: 53px;
  display: none;
  @media (max-width: 600px) {
    display:block;
    padding: 0 24px;
  }
  &__title {
    font-weight: 700;
    font-size: 40px;
    line-height: 100%;
  }

  &__title-text {
    font-weight: 700;
    font-size: 40px;
    line-height: 100%;
    opacity: 0.4;
    padding-bottom: 30px;

    div:first-child {
      padding-bottom: 23px;
    }
  }

  &__stake-button, &__connect-button, &__withdraw-button {
    width: 100%;
    height: 104px;
    border-radius: 32px;
    background: black;
    cursor: pointer;

    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    color: #F9D549;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      opacity: 0.8;
    }
    &__disabled {
      opacity: 0.7;
      pointer-events: none;
    }
  }

  &__withdraw-button {
    margin-top: 8px;
    background: rgba(255, 255, 255, 0.4);
    color: #000000;
    height: 64px;
  }
}