.withdraw-modal-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  display: flex;
  margin-left: 61px;
  box-sizing: border-box;

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(28px);
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: 600px) {
    margin: 48px 24px 74px 24px;
    height: 100vh;

    .withdraw-modal-space {
      display: none;
    }
  }
}

.withdraw-modal {
  width: 100%;
  max-width: 590px;

  &__title {
    color: #FFFFFF;
    font-weight: 700;
    font-size: 80px;
    line-height: 100%;
    padding-top: 152px;
    padding-bottom: 56px;
  }

  &__cards {
    margin-bottom: 70px;
  }

  &-space {
    flex: 1;
  }

  &__close {
    position: absolute;
    top: 35px;
    right: 95px;
    padding: 8px 10px;
    background: rgba(255, 255, 255, 0.4);;
    color: white;
    text-align: center;
    border-radius: 80px;
    cursor: pointer;
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    z-index: 9999;
  }
  
  &__activeDisclaimer {
    top: 85px;
  }


  &__activeDisclaimer {
    top: 85px;
  }


  @media screen and (max-width: 600px) {
    padding: 0;
    width: calc(100% - 48px);
    height: fit-content;

    &__title {
      font-size: 40px;
      line-height: 100%;
      padding-top: 0;
      padding-bottom: 24px;
    }

    &__cards {
      margin-bottom: 10px;
    }
    &__titleWithDisclaimer {
      padding-top: 70px;
    }
    &__close {
      top: 20px;
      right: 50px;
      font-size: 14px;
    }
    &__activeDisclaimer {
      top: 70px;
    }
  }
}