.header-wrapper {
    padding: 61px 61px 0;
    @media screen and (max-width: 600px) {
        padding: 30px 24px;
        padding-top: 95px;
        padding-bottom: 0;
    }
}

.header-wrapper-disclaimer {
    padding: 61px;
    padding-top: 125px;
    padding-bottom: 0;

    @media screen and (max-width: 600px) {
        padding: 30px 24px;
        padding-top: 95px;
        padding-bottom: 0;
    }
    @media screen and (max-width: 440px) {
        padding-top: 115px;
    }
}
