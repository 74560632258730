.withdraw-card-back {
    width: 590px;
    height: 232px;
    margin-bottom: 8px;
    background: #fdfdfd;
    position: relative;

    &__warning,
    &__description {
        font-weight: 500;
        font-size: 16px;
        line-height: 120%;
    }

    &__warning {
        margin-bottom: 12px;
        width: 100%;

        span {
            opacity: 0.4;
        }

        .back_btn {
            cursor: pointer;
            margin-left: 80%;
            font-size: 30px;
            line-height: 10px;

            &:hover {
                opacity: 1 !important;
            }
        }
    }

    &__actions {
        position: absolute;
        right: 24px;
        bottom: 24px;
        display: flex;
        justify-content: flex-end;
        width: 93%;

        font-weight: 500;
        font-size: 16px;
        line-height: 120%;

        &__cancel,
        &__agree {
            border: none;
            padding: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        &__cancel {
            margin-right: 8px;
            text-transform: uppercase;
            width: 45%;
            background-color: black;
            border-radius: 136px;
            color: white;
        }

        &__agree {
            background: #f9d549;
            border-radius: 136px;
            text-transform: uppercase;
            width: 45%;
            &:disabled {
                cursor: not-allowed;
            }
        }
    }

    @media screen and (max-width: 600px) {
        width: 100%;
        height: 225px;

        &__warning,
        &__description {
            font-weight: 500;
            font-size: 12px;
            line-height: 120%;
        }

        &__actions {
            font-size: 12px;
            bottom: 18px;
            right: 18px;
            flex-direction: column;
            align-items: center;

            &__cancel,
            &__agree {
                width: 90%;
                margin-bottom: 10px;
            }
        }
    }
}

.withdraw-card-black {
    width: 590px;
    height: 232px;
    margin-bottom: 8px;
    background: black;
    position: relative;
    color: white;

    &__warning,
    &__description {
        font-size: 16px;
        line-height: 120%;
        opacity: 0.4;
    }

    &__description {
        font-size: 32px;
        font-weight: 700;
    }
    &__warning {
        margin-bottom: 12px;
    }

    &__title {
        font-size: 32px;
        color: white;
    }

    &__actions {
        position: absolute;
        right: 24px;
        bottom: 24px;
        display: flex;
        justify-content: space-between;
        width: 93%;

        font-weight: 500;
        font-size: 16px;
        line-height: 120%;

        &__cancel,
        &__agree {
            border: none;
            padding: 12px 12px 0 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            &:hover {
                opacity: 0.7;
            }
        }

        &__cancel {
            margin-right: 8px;
            text-transform: uppercase;
            background-color: black;
            border-radius: 136px;
            color: white;
        }

        &__agree {
            border-radius: 136px;
            text-transform: uppercase;
        }
    }

    @media screen and (max-width: 600px) {
        width: 100%;
        height: 200px;

        &__warning,
        &__description {
            font-weight: 500;
            font-size: 12px;
            line-height: 120%;
        }

        &__actions {
            font-size: 12px;
            bottom: 18px;
            right: 18px;
        }
    }
}
