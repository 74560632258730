.button {
  cursor: pointer;
  background: #F9D549;
  border-radius: 152px;
  padding: 18px 80px;
  width: 100%;
  box-sizing: border-box;

  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 700;
  font-size: 16px;
  line-height: 120%;

  &--disabled {
    pointer-events: none;
    color: rgba(0,0,0, 0.7);
  }
}