.snapshot-modal-wrapper {
  width: 100%;
  max-width: 590px;

  @media screen and (min-width: 600px) and (max-height: 840px) {
    height: 100%;
    overflow: auto;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  @media screen and (max-width: 600px) and (max-height: 740px) {
    height: 100%;
    overflow: auto;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.snapshot-modal-container {
  @media screen and (max-width: 600px) {
    margin-top: 0 !important;
  }
}

.snapshot-modal {
  margin-top: 50px;
  width: 100%;
  min-height: 640px;
  max-width: 590px;

  &--loading {
    min-height: 0;
  }

  &-container {
    align-items: flex-end;
  }

  &_activeDisclaimer {
    margin-top: 140px
  }

  &-space {
    flex: 1;
  }

  &__close {
    position: absolute;
    top: 35px;
    right: 95px;
    padding: 8px 10px;
    background: rgba(255, 255, 255, 0.4);;
    color: black;
    text-align: center;
    border-radius: 80px;
    cursor: pointer;
    font-size: 16px;
    z-index: 9999;
  }
  &__closeButtonWithDisclaimer {
    top: 81px;
  }
  @media screen and (max-width: 600px) {
    padding: 0;
    width: calc(100% - 48px);
    min-height: 435px;

    &--loading {
      min-height: 0;
    }

    &__close {
      top: 3px;
      right: 30px;
      font-size: 12px;
    }
  }
  @media screen and (max-width: 440px) {
    &__closeButtonWithDisclaimer {
      top: 115px;
    }
    &_activeDisclaimer {
      margin-top: 160px
    }
  }
}