.history-modal {
  display: flex;
  align-items: flex-end;
  padding-left: 61px;

  @media screen and (max-width: 600px) {
    padding-left: 16px;
  }
  @media screen and (max-width: 440px) {
    align-items: normal;
    margin-top: 16vh;
  }
}

.history-modal-wrapper {
  display: flex;
  width: 100%;
  overflow: scroll;

  @media screen and (max-width: 600px) {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
  
    /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }
  
}

.history-modal-container {
  margin-left: 0;
}