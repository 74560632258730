.withdraw-card {
    width: 590px;
    height: 232px;
    margin-bottom: 8px;
    background: #f9d549;
    cursor: pointer;
    // pointer-events: none;

    &__date {
        font-weight: 500;
        font-size: 16px;
        line-height: 120%;
        opacity: 0.4;
        margin-bottom: 12px !important;
    }

    &__value,
    &__points,
    &__pointPerDay {
        font-weight: 700;
        font-size: 32px;
        line-height: 120%;
    }

    &__points,
    &__pointPerDay {
        opacity: 0.4;
    }

    &__line {
        display: flex;
        justify-content: space-between;

        font-weight: 500;
        font-size: 16px;
        line-height: 120%;
        margin-top: 32px;
    }
}
