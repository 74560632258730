.zero-inflation__container {
    min-height: 100vh;
    box-sizing: border-box;
    position: relative;

    &__container {
        width: 100%;
        min-height: calc(100vh - 280px);
        color: #ffffff;
        margin-bottom: 76px;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
}

.before-yield {
    padding: 0 61px;
    margin-bottom: 72px;
    margin-top: 35px;
    @media (max-width: 600px) {
        padding: 0 24px;
    }
    h1 {
        margin: 0;
        padding: 0;

        font-style: normal;
        font-weight: 700;
        font-size: 80px;
        line-height: 100%;

        text-transform: uppercase;

        color: #000000;

        opacity: 0.4;
        @media (max-width: 470px) {
            font-size: 60px;
        }
    }
    h3 {
        margin: 0;
        padding: 0;
        margin-bottom: 8px;

        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color: #000000;

        line-height: 120%;

        text-transform: uppercase;
    }
}

.pools,
.history {
    margin-bottom: 70px;
    &__head-content {
        padding: 0 61px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-bottom: 24px;
        @media (max-width: 600px) {
            padding: 0 24px;
        }
        @media (max-width: 1540px) {
            align-items: flex-end;
        }

        &__headline {
            margin: 0;
            padding: 0;

            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 120%;

            color: #000000;
        }
        &__text-container {
            width: 100%;
            display: flex;
            justify-content: space-between;
            &__rewards {
                text-transform: uppercase;
                font-weight: 700;
                font-size: 24px;
                line-height: 120%;
            }

            @media (max-width: 1540px) {
                flex-direction: column;
                justify-content: space-between;
                &__rewards {
                    opacity: 0.4;
                    font-size: 24px;
                }
            }
        }

        &__buttons,
        &:focus,
        &:hover {
            background: none;
            border: none;
            outline: none;
        }

        &__arrows {
            display: none;
            @media (max-width: 1540px) {
                display: flex;
            }
            justify-content: space-between;

            &_left {
                transform: rotate(180deg);
            }

            svg {
                path {
                    stroke: #000000;
                }
            }

            &_left,
            &_right {
                cursor: pointer;
                opacity: 0.5;
                transition: all ease-in-out 0.2s;

                &:hover {
                    opacity: 1;
                }
            }
        }
    }
    &__container {
        display: flex;

        overflow: scroll;
        scroll-behavior: smooth;

        &::-webkit-scrollbar {
            display: none;
        }
    }
    &__item {
        * {
            margin: 0;
            padding: 0;
        }

        &-value {
            margin-left: 20px;
            word-break: break-word;
            &.user__tokens {
                text-align: right;
            }

            @media screen and (max-width: 600px) {
                margin-left: 0px;
            }
        }

        min-width: 490px;
        min-height: 349px;

        border-radius: 24px;

        margin-left: 8px;
        &:first-child {
            margin-left: 61px;

            @media screen and (max-width: 600px) {
                margin-left: 24px;
            }
        }

        // &:last-child {
        //     margin-right: 409px;
        // }

        &__content {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &__header {
                font-style: normal;
                // font-weight: 500;
                font-size: 16px;
                line-height: 120%;

                text-transform: uppercase;

                color: #000000;

                opacity: 0.4;

                &__period {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    h3 {
                        // font-weight: 500;
                        font-size: 16px;
                    }

                    @media screen and (max-width: 600px) {
                        justify-content: flex-start;
                        flex-direction: column;

                        margin-top: 20px;
                        &:first-child {
                            margin-top: 0px;
                        }
                    }
                }
            }

            &__footer {
                &__user-data {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 120%;
                    /* identical to box height, or 29px */

                    display: flex;
                    align-items: flex-end;
                    text-transform: uppercase;

                    color: #000000;

                    display: flex;
                    justify-content: space-between;

                    @media screen and (max-width: 600px) {
                        justify-content: flex-start;
                        flex-direction: column;

                        align-items: flex-start;
                    }
                }
            }
        }
    }
}

.pools {
    &__item {
        width: 100%;
        &:first-child {
            margin-left: 61px;

            @media screen and (max-width: 600px) {
                margin-left: 24px;
            }
        }

        &:last-child {
            margin-right: 61px;

            @media screen and (max-width: 600px) {
                margin-right: 24px;
            }
        }

        @media screen and (max-width: 600px) {
            min-width: calc(100% - 24px * 2);
        }
    }
}

.history {
    &__item {
        // width: 100%;
        // &:first-child {
        //     margin-left: 61px;

        //     @media screen and (max-width: 600px) {
        //         margin-left: 24px;
        //     }
        // }

        // &:last-child {
        //     margin-right: 61px;

        //     @media screen and (max-width: 600px) {
        //         margin-right: 24px;
        //     }
        // }

        @media screen and (max-width: 600px) {
            width: 100%;
            min-width: calc(100% - 24px * 2);
        }
    }
}

.history {
    align-items: center;
    &__container {
        padding: 0 61px;
        @media (max-width: 600px) {
            padding: 0 24px;
        }
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap;

        gap: 8px 8px;
    }

    &__item {
        width: 100%;

        max-width: calc(50% - 4px);
        @media (max-width: 1365px) {
            max-width: 618px;
        }
        min-height: 232px;

        margin: 0px;
        &:first-child {
            margin: 0;
        }

        &:last-child {
            margin: 0;
        }

        // @media (max-width: 1365px) {
        //   width: 50%;
        //   margin: 0;
        // }

        &__content {
            &__footer {
                &__user-data {
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;
                    align-items: flex-start;

                    h1,
                    h3 {
                        font-style: normal;
                        font-weight: 700;
                        font-size: 32px;
                        line-height: 120%;

                        text-transform: uppercase;

                        color: #000000;
                    }

                    h3 {
                        opacity: 0.4;
                    }
                }
            }
        }
    }
}

.pools__button-wrapper {
    padding: 0 61px;
    display: flex;
    gap: 8px 8px;
    @media (max-width: 1024px) {
        flex-direction: column;
        // padding: 0 24px;
    }
    @media (max-width: 600px) {
        padding: 0 24px;
    }
    margin-top: 16px;

    // width: 100%;
    // margin-bottom: 60px;
    // overflow: hidden;
    // position: relative;

    .pools__button {
        // height: 87px;
        background-color: #000000;
        color: #f9d549;

        padding-top: 32px;
        padding-bottom: 32px;

        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 120%;

        border-radius: 32px;

        text-transform: uppercase;

        &._disabled {
            opacity: 0.4;
        }
    }
}

.pools__container-wrapper {
    width: 100%;
    // margin-bottom: 60px;
    overflow: hidden;
    // position: relative;
}
