.header {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    // padding-bottom: 65px;
    z-index: 1;
    top: 0;
    left: 0;

    @media screen and (max-width: 600px) {
        &__button {
            font-size: 13px !important;
            width: 150px !important;

            &--wrong-network,
            &--lottery {
                font-size: 13px !important;
                line-height: 200% !important;
            }
        }
    }

    &__section {
        flex: 1;

        &__navigation-container {
            display: flex;
            button {
                background: none;
                outline: none;
                border: none;
                &:focus,
                &:active {
                    border: none;
                }
                cursor: pointer;
            }
            nav {
                margin-left: 20px;
                .header__href {
                    margin-left: 12px;
                    text-transform: uppercase;
                    opacity: 0.4;
                    &._active {
                        opacity: 1;
                    }
                }
            }

            @media screen and (max-width: 900px) {
                flex-direction: column;
                img {
                    height: 20px;
                    width: auto;
                }

                nav {
                    display: flex;
                    // flex-direction: column;
                    align-items: center;

                    margin-left: 0px;
                    margin-top: 20px;
                    .header__href {
                        &:first-child{
                            margin-left: 0px;
                        }
                    }
                }
            }
        }

        &._desktop {
            display: flex;
            @media screen and (max-width: 900px) {
                display: none;
            }
        }

        &._mobile {
            display: none;
            @media screen and (max-width: 900px) {
                display: flex;
            }
        }
    }

    &__href {
        font-weight: 700;
        font-size: 16px;
        line-height: 120%;
        color: black;
        text-decoration: none;
        text-align: center;
    }

    &__logo {
        text-align: center;
        font-weight: 700;
        font-size: 20px;
    }

    &__button {
        box-sizing: border-box;
        font-weight: 700;
        font-size: 20px;
        line-height: 120%;
        background: #000000;
        cursor: pointer;
        color: #f9d549;
        border-radius: 88px;
        padding: 8px 10px;
        width: 207px;

        display: flex;
        justify-content: center;
        align-items: center;

        &--wrong-network {
            box-sizing: border-box;
            font-weight: 700;
            font-size: 20px;
            line-height: 120%;
            padding: 8px 10px;
            background: #600b10;
            border-radius: 88px;
            color: white;
            cursor: pointer;

            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
        }

        &--lottery {
            background: rgba(255, 255, 255, 0.4);
            border-radius: 88px;
            padding: 8px 10px;

            font-weight: 700;
            font-size: 20px;
            line-height: 130%;
            color: #000000;

            cursor: pointer;
            margin-right: 12px;
        }
    }

    &__connection-container {
        display: flex;
        justify-content: end;
    }

    &__links {
        display: flex;
    }
}
