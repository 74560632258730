.disclaimer {
    background-color: #ff6871;
    padding: 2rem 0;
    color: white;
    width: 100%;
    text-align: center;
    font-weight: 500;
    width: 100%;
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 1000;

    &__success {
        background-color: #31bd25;
        color: white;
    }

    @media screen and (max-width: 500px) {
        font-size: 13px;
        padding: 1rem 0;
    }
}
