.snapshot {
  background: #F9D549;
  border-radius: 24px;
  padding: 26px 24px;
  margin-right: 8px;
  width: 550px;
  min-height: 653px;

  &__title {
    opacity: 0.4;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    padding-bottom: 22px;
  }
  &__value, &__description {
    font-weight: 700;
    font-size: 84px;
    line-height: 100%;
  }
  &__description {
    opacity: 0.4;
    padding-bottom: 29%;
    
  }
  &__withDisclaimer {
    @media screen and (max-height: 750px) {
        padding-bottom: 10% !important;
      }
  }

  &__text {
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    max-width: 370px;
  }

  &__lootbox {
    min-height: 160px;
    position: relative;

    font-weight: 700;
    font-size: 84px;
    line-height: 100%;

    display: flex;
    align-items: flex-end;

    padding-bottom: 20px;

    &__text {
      position: absolute;
      bottom: 45px;
      right: 120px;
      opacity: 0.4;
    }

    &__image {
      width: 220px;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    margin-top: 34px;
  }

  &__button {
    &--view, &--claim {
      cursor: pointer;
      font-weight: 500;
      font-size: 16px;
      line-height: 120%;
      border-radius: 136px;
      padding: 8px 12px;
    }

    &--claim {
      background: #000000;
      color: #FFFFFF;
      margin-right: 12px;
      text-align: center;
      max-width: 65px;
    }

    &--disable {
      pointer-events: none;
      opacity: 0.5;
    }

    &--view {
      background: #FFFFFF;
      color: #000000;
    }
  }
  &__showDisclaimer {
    @media screen and (max-height: 750px) {
      min-height: 540px !important;
    }
  }

  @media screen and (max-width: 600px) {
    padding: 16px;
    width: 330px;
    min-height: 577px;

    &__title {
      font-size: 13px;
    }
    &__value {
      font-size: 38px;
    }
    &__description {
      font-size: 38px;
      padding-bottom: 220px;
    }
    &__text {
      font-size: 14px;
    }
    &__lootbox {
      min-height: 130px;

      &__image {
        width: 180px;
      }

      &__text {
        font-size: 50px;
        bottom: 20%;
        right: 30px;
      }
    }

    &__buttons {
      padding-bottom: 80px;
    }

    &__button {
      &--view, &--claim {
        font-size: 14px;
        padding: 6px 10px;
      }
    }
  }
  @media screen and (max-width: 600px) {
    width: 315px;
  }
}