.home-page {
  margin-bottom: 53px;
  padding: 0 61px;
  &__title {
    font-weight: 700;
    font-size: 80px;
    line-height: 100%;

    &__old {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__title-text {
    font-weight: 700;
    font-size: 80px;
    line-height: 100%;
    opacity: 0.4;
    padding-bottom: 63px;
  }

  &__input-container {
    display: flex;
  }

  &__income {
    font-weight: 700;
    font-size: 16px;
    line-height: 100%;
  }

  &__stake-button, &__connect-button {
    width: 100%;
    // height: 126px;
    border-radius: 32px;
    background: black;
    cursor: pointer;

    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    color: #F9D549;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      opacity: 0.8;
    }
  }

  &__stake-button {
    // height: 232px;
    flex: 1;
    margin-left: 8px;

    &--disabled {
      opacity: 0.7;
      pointer-events: none;
    }
  }

  &__connect-button {
    height: 126px;
  }
  @media (max-width: 600px) {
    display: none;
  }
}