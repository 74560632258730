.stake-input {
  position: relative;
  flex: 2;

  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  &__withdraw-container {
    display: flex;

    & div {
      cursor: pointer;
      border-bottom: 1px dashed;

      &:first-child {
        margin-right: 8px;
      }
    }
  }
  &--disabled {
    opacity: 0.7;
    pointer-events: none;
  }
  &__line {
    display: flex;
    justify-content: space-between;
  }

  &__info-data {
    display: flex;
    justify-content: space-between;
    // position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    // padding: 32px;

    &__max {
      cursor: pointer;
    }
    &_value {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }
  }

  &__input {
    font-weight: 700;
    // font-size: 53px;
    font-size: 32px;
    line-height: 100%;
    background: transparent;
    border: none;
    // padding-top: 4px;
    margin: 14px 0;
  }
}