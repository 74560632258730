:root{
    --light-gray: #3E3E3E;
}

body {
    scroll-behavior: smooth;

    font-family: 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #F9D549;
    color: black;
    padding: 0;
    margin: 0;
}

.ReactModal__Body--open .App {
    height: 100vh;
    overflow: hidden;
}

* {
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

body > iframe {
    display: none;
}
