.stake-modal {
    padding: 94px 24px 24px 24px;

    &-container {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        box-sizing: border-box;
        background: #f9d549;
        overflow-x: hidden;
        overflow-y: auto;

        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__close {
        position: absolute;
        top: 31px;
        right: 24px;

        background: rgba(255, 255, 255, 0.4);
        border-radius: 88px;
        padding: 8px 10px;

        font-weight: 700;
        font-size: 20px;
        line-height: 120%;
    }
    &__activeDisclaimer {
        top: 71px;
        @media screen and (max-width: 440px) {
            top: 111px;
        }
    }

    &__title {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
    }

    &__input {
        font-weight: 700;
        font-size: 56px;
        line-height: 100%;
        border: none;
        background: transparent;
    }

    &__income {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        padding-top: 12px;
    }

    &__info-data {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;

        &__max {
            padding-bottom: 6px;
        }

        &_value {
            display: flex;
            flex-direction: column;
        }
    }

    &__stake-button {
        height: 64px;
        background: #000000;
        border-radius: 32px;
        width: 100%;

        font-weight: 700;
        font-size: 20px;
        line-height: 120%;
        color: #f9d549;

        margin-top: 24px;

        display: flex;
        align-items: center;
        justify-content: center;

        &--disabled {
            opacity: 0.4;
            cursor: default;
            pointer-events: none;
        }
    }
}

.activeDisclaimer {
    padding-top: 121px;
}
