.how-it-works-modal {
  width: 100%;
  max-width: 590px;
  height: fit-content;

  &__title {
    color: #FFFFFF;
    font-weight: 700;
    font-size: 80px;
    line-height: 100%;
    padding-top: 152px;
    padding-bottom: 56px;
    width: 620px;
  }

  &-space {
    flex: 1;
  }

  &__close {
    position: absolute;
    top: 35px;
    right: 95px;
    padding: 8px 10px;
    background: rgba(255, 255, 255, 0.4);;
    color: black;
    text-align: center;
    border-radius: 80px;
    cursor: pointer;
    font-size: 16px;
    z-index: 9999;
  }

  &__card {
    background: #F9D549;
    border-radius: 24px;
    padding: 24px;
    margin-bottom: 20px;

    &__title {
      opacity: 0.4;
      font-weight: 500;
      font-size: 16px;
      line-height: 120%;
    }

    &__text {
      font-weight: 700;
      font-size: 16px;
      line-height: 120%;

      ol {
        padding-left: 19px;
      }
    }
  }

  @media screen and (max-width: 600px) {
    padding: 0;
    margin-bottom: 40px;
    width: calc(100% - 48px);

    &__title {
      font-size: 36px;
      line-height: 100%;
      padding-top: 0;
      padding-bottom: 24px;
    }

    &__close {
      top: 3px;
      right: 30px;
      font-size: 12px;
    }

    &__card {
      padding: 16px;

      &__text {
        font-size: 14px;
      }
    }
  }
  
}

.showedDisclaimer {
  @media screen and (max-width: 440px) {
    margin-top: 72px;
    .how-it-works-modal__title {
      font-size: 30px;
    }
  }
}